// Add extra ratio classes
.noUi-value {
  padding-top: 5px;
}

.noUi-horizontal {
  margin: 0 15px 60px;
}

.noUi-pips {
  pointer-events: none;
}