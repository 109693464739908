@import "../other/variables";
@import "~material-colors/dist/colors.scss";

.dock-layout {
  display: flex;
  position: relative;
  flex: 0 0 auto;

  &.dock-layout-full-height {
    flex-grow: 1;
  }

  &.dock-layout-full-width {
    align-self: stretch;
  }

  &.dock-layout-wrap {
    flex-wrap: wrap;
  }

  &.dock-layout-overlay {
    position: absolute;
    z-index: 1;
    top: 0;

    &.dock-layout-full-height {
      height: 100%;
    }
  }

  &.dock-layout-columns {
    flex-direction: column;
  }

  &.dock-layout-rows {
    flex-direction: row;
  }

  &.dock-layout-center-horizontal {
    align-items: center;

    > p {
      text-align: center;
    }
  }

  &.dock-layout-center-vertical {
    justify-content: center;
  }

  > h1 {
    font-weight: 300;
    font-size: 2em;
    color: $md-dark-text-secondary;
  }

  > p,
  > ul {
    color: $md-dark-text-secondary;
    font-weight: 600;
    max-width: 600px;
  }
}
