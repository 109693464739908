@import '../../sass/other/variables';

.strikethrough {
    text-decoration: line-through;
}

.sale-price {
    color: $color-discount;
}

.nowrap {
    white-space: nowrap
}