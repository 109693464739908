.link-dotted {
    cursor: pointer;
    border-bottom: 1px black dotted;

    &:hover, 
    &:active,
    &:focus {
        text-decoration: none;
    }
}
