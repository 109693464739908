@import '../../sass/other/variables';

select-grid {
  position: relative;
  opacity: 0;
  pointer-events: none;
  width: calc(100% + 8px);

  &.visible {
    opacity: 1;
    pointer-events: all;
    transition: opacity 200ms;
  }

  .select-grid-items {
    margin-left: -4px;
    margin-right: -4px;
  }

  .select-grid-item {
    position: relative;
    display: inline-block;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 4px;
    font-weight: bold;
    line-height: 21px;
    text-align: center;
    background-color: $gray-white;
    white-space: nowrap;

    &.select-grid-item-selected {
      background-color: $brand-secondary;
      color: white;
    }

    &[disabled] {
      background-color: $gray-light;
      cursor: auto;
    }
  }

  .select-grid-title {

  }
}
