input[type="checkbox"].switch {
  position: absolute;
  opacity: 0;
}

/* Normal Track */
input[type="checkbox"].switch+div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, .4);
  border-radius: 999px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition-duration: .4s;
  -webkit-transition-property: background-color, box-shadow;
  box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0.4);
}

/* Checked Track (Blue) */
input[type="checkbox"].switch:checked+div {
  width: 40px;
  background-position: 0 0;
  background-color: #3b89ec;
  border: 1px solid #0e62cd;
  box-shadow: inset 0 0 0 10px rgba(59, 137, 259, 1);
}

/* Tiny Track */
input[type="checkbox"].tinyswitch.switch+div {
  width: 34px;
  height: 18px;
}

/* Big Track */
input[type="checkbox"].bigswitch.switch+div {
  width: 50px;
  height: 25px;
}

/* Green Track */
input[type="checkbox"].green.switch:checked+div {
  background-color: #00e359;
  border: 1px solid rgba(0, 162, 63, 1);
  box-shadow: inset 0 0 0 10px rgba(0, 227, 89, 1);
}

/* Normal Knob */
input[type="checkbox"].switch+div>div {
  float: left;
  width: 18px;
  height: 18px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(.54, 1.85, .5, 1);
  -webkit-transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color, box-shadow;
  -moz-transition-timing-function: cubic-bezier(.54, 1.85, .5, 1);
  -moz-transition-duration: 0.4s;
  -moz-transition-property: transform, background-color;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 0 1px
  rgba(0, 0, 0, 0.4);
  pointer-events: none;
}

/* Checked Knob (Blue Style) */
input[type="checkbox"].switch:checked+div>div {
  -webkit-transform: translate3d(20px, 0, 0);
  -moz-transform: translate3d(20px, 0, 0);
  background-color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 0 1px
  rgba(8, 80, 172, 1);
}

/* Tiny Knob */
input[type="checkbox"].tinyswitch.switch+div>div {
  width: 16px;
  height: 16px;
}

/* Checked Tiny Knob (Blue Style) */
input[type="checkbox"].tinyswitch.switch:checked+div>div {
  -webkit-transform: translate3d(16px, 0, 0);
  -moz-transform: translate3d(16px, 0, 0);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 0 1px
  rgba(8, 80, 172, 1);
}

/* Big Knob */
input[type="checkbox"].bigswitch.switch+div>div {
  width: 23px;
  height: 23px;
}

/* Checked Big Knob (Blue Style) */
input[type="checkbox"].bigswitch.switch:checked+div>div {
  -webkit-transform: translate3d(25px, 0, 0);
  -moz-transform: translate3d(16px, 0, 0);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 0px 0 1px
  rgba(8, 80, 172, 1);
}

/* Green Knob */
input[type="checkbox"].green.switch:checked+div>div {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px
  rgba(0, 162, 63, 1);
}