@import "../../sass/mixins/isaac-responsive";
@import "../../sass/other/variables";

/* Specification of the Dock filter bar. Provides a general styling for all filter bars across dock */
.dock-filter-bar {
  margin: (($grid-gutter-width / 4) - 4px) (($grid-gutter-width / 2) - 6px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .dock-filter-bar-item {
    flex: 1 1 auto;
    margin: 4px 6px;
    width: 160px;
    max-width: 300px;

    @include isaac-respond-max-width(lg) {
      flex: 0 1 20%;
    }

    &.dock-filter-bar-item-fixed {
      flex: 0 0 0;
      width: auto;

      &.dock-filter-bar-item-fixed-right {
        margin-right: 0;
      }

      &.dock-filter-bar-item-fixed-left {
        margin-left: 0;
      }
    }
  }
}

.dock-filter-item-inline {
  width: 250px;
  margin-bottom: 16px;
}

@include isaac-respond-max-width(md) {
  .dock-filter-bar {
    flex-wrap: wrap;

    .dock-filter-bar-item {
      flex-grow: 1;
      flex-basis: 250px;

      &.dock-filter-bar-item-fixed {
        flex: 0 0 auto;
      }
    }
  }
}

@include isaac-respond-max-width(xs) {
  .dock-filter-bar {
    .dock-filter-bar-item-fixed {
      margin-left: auto !important;   // Important because of dock-filter-bar-item-fixed-left
      margin-right: auto !important;  // Important because of dock-filter-bar-item-fixed-right
      margin-top: 8px;
    }
  }
}
