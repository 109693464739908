@import "../mixins/isaac-responsive";
@import "~material-colors/dist/colors.scss";

.dock-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px;
  flex: 0 0 auto;
  max-width: 100%;

  h1 {
    font-weight: 300;
    font-size: 2em;
    color: $md-dark-text-secondary;
  }

  .dock-message-text {
    font-weight: 100;
    font-size: 1.5em;
    color: rgba(0,0,0,0.54);
    text-align: center;
    max-width: 600px;

    &.dock-message-text-small {
      font-size: 1em;
      font-weight: 600;
    }

    ul {
      display: inline-block;
      text-align: left;
    }
  }

  .dock-message-action {
    margin-top: 16px;

    .btn {
      margin: 0 8px;
    }
  }

  .dock-message-image {
    max-width: 160px;
    margin: 8px auto;
  }

  &.dock-message-loading {
    [class*="spinner-"] {
      margin: 8px auto;
    }
  }

  &.dock-message-margin {
    padding: 100px 0;
  }
}

@include isaac-respond-max-width(xs) {
  .dock-message {
    margin-top: 16px;

    .dock-message-text {
      font-size: 1em;
    }

    .dock-message-action {
      margin-top: 0;
      text-align: center;

      .btn {
        display: block;
        margin: 16px auto;
      }
    }
  }
}
