@import "../other/variables";

.uib-datepicker-popup {
  &.dropdown-menu {
    padding: 12px;
  }

  .uib-daypicker:focus,
  .uib-monthpicker:focus,
  .uib-yearpicker:focus {
    outline: none;
  }

  .btn-default {
    background-color: white;
    border: none;

    &:hover,
    &.active {
      background-color: $brand-secondary;
      color: white;
    }

    &.active {
      box-shadow: none
    }
  }
}
