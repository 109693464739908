@import "../mixins/isaac-responsive";
@import "../other/variables";

.return {
  .panel-default {
    border: medium none;

    & > .panel-heading {
      padding: 0;
      background: none;
      font-weight: bold;
      border: medium none;
    }
  }

  .return-intro {
    @include isaac-respond-min-width(sm) {
      margin-bottom: 20px;
    }
  }

  .return-rma {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;

    @include isaac-respond-min-width(sm) {
      border-bottom: medium none;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
  .return-customer {
    padding-top: 20px;
    padding-bottom: 20px;

    @include isaac-respond-min-width(sm) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .return-order {
    label {
      padding-left: 5px;

      &.disabled {
        color: $gray-lighter;
        cursor: not-allowed;
      }
    }

    input[type="checkbox"] {
      margin: 5px 5px 5px 0;
      position: relative;

      &:disabled {
        color: $gray-lighter;
        cursor: not-allowed;
      }
    }
  }

  .return-amount,
  .return-btn {
    text-align: center;
  }

  .return-comment {
    .form-group {
      margin: 0 auto;
      max-width: 500px;
    }
  }

  .return-exchange-orders {
    button {
      margin: 0 8px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .return-amount {
    padding: 20px 0;
  }

  .return-btn {
    margin-bottom: 10px;
  }
}
