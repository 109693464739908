@import "../mixins/isaac-responsive";

dock-header + .tabs-container {
  position: relative;
  top: -1px;
}

.nav-tabs {
  position: relative;
}

.tabs-controls {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > * {
    flex: 0 1 auto;
    margin: 0 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include isaac-respond-max-width(xs) {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding-top: 12px;

    > * {
      margin: 0 10px 5px;
    }
  }
}
