@import '~material-colors/dist/colors.scss';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_opacity';
@import '../other/variables';
@import '../mixins/isaac-responsive';

.btn {
  font-weight:bold;

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    @include opacity(.5);
  }

  &.btn-primary {
    background-color: $brand-secondary;
    border-color: $brand-secondary;
    color: white;

    &:hover {
      background-color: $brand-secondary-dark;
      border-color: $brand-secondary-dark;
    }
  }

  &.btn-secondary {
    border:none;
    width:100%;
    text-align:center;
    background-color: $gray-white;
  }

  &.btn-fixed-width {
    width: 100px;
  }

  .fa.fa-spinner {
    display:none;
  }

  &.btn-link.btn-danger {
    color: $md-red-500;
  }

  .btn-icon {
    margin-right: 6px;
  }
}
.btn:disabled {
  i {
    display: inline;
  }
}

.search {
  margin-bottom:10px;

  @include isaac-respond-min-width(sm) {
    margin-bottom:-33px;
  }
}

.button-footer {
  padding:20px 0;
  margin-top:20px;
  border-top:1px solid #ddd;
}
