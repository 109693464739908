@import "../mixins/isaac-responsive";

.stock-form {
  text-align: center;
}

.size-edit {
  margin-top: 48px;

  &.size-edit-inline {
    margin-top: 0;
  }
}

.stock-edit {
  display: flex;
  margin-top: 48px;
  flex-wrap: wrap;

  &.stock-edit-inline {
    margin-top: 0;

    .stock-edit-item,
    .size-edit-inline {
      display: flex;
      flex: 0 0 auto;
      padding: 8px 12px;
    }

    .stock-edit-item {
      .stock-edit-item-label {
        flex: 1 1 auto;
        white-space: nowrap;
      }

      .stock-edit-item-controls {
        flex: 1 1 auto;

        .stock-edit-item-controls-input {
          min-width: 20px;
        }
      }
    }
  }

  .stock-edit-title,
  .stock-edit-message {
    flex: 0 0 100%;
  }

  .stock-edit-item {
    display: flex;
    flex: 0 0 33.3%;
    padding: 8px 12px;

    @include isaac-respond-max-width(lg) {
      flex: 0 0 50%;
    }

    @include isaac-respond-max-width(sm) {
      flex: 0 0 100%;
    }

    .stock-edit-item-label {
      flex: 1 1 20%;
      font-weight: bold;
      line-height: 35px;
      padding-right: 8px;
      text-align: left;
    }

    .stock-edit-item-controls {
      display: flex;
      flex: 1 1 80%;

      .input-group-btn {
        width: auto;
      }

      .stock-edit-item-controls-button {
        flex: 0 0 auto;
      }

      .stock-edit-item-controls-input {
        flex: 1 1 auto;
      }
    }
  }
}

.stock-form-actions {
  margin-top: 48px;
  display: flex;
  justify-content: space-around;

  [class*=spinner-] {
    margin-left: 8px;
  }

  @include isaac-respond-max-width(sm) {
    flex-direction: column;
    height: 100px;
  }
}