@import '../../../../node_modules/material-colors/dist/colors.scss';;

.input-counter-container {
  position: relative;

  input-counter {
    position: absolute;
    bottom: 6px;
    right: 8px;
    user-select: none;
    font-size: 0.7em;
    color: $md-dark-text-secondary;
  }

  input {
    padding-right: 40px;
  }

  textarea + input-counter {
    bottom: -20px;
  }

  + .help-block {
    padding-right: 56px;
  }
}

