@import '../other/variables';
@import '~material-colors/dist/colors.scss';

// We use "!important" since these are one-property classes, so they are only used to override

.color-discount {
  color: $color-discount !important;
}

.color-error {
  color: $color-error !important;
}

.color-danger {
  color: $color-danger !important;
}

.color-warning {
  color: $color-warning !important;
}

.color-primary {
  color: $brand-primary !important;
}

.color-secondary {
  color: $brand-secondary !important;
}

.color-text-primary {
  color: $md-dark-text-primary !important;
}

.color-text-secondary {
  color: $md-dark-text-secondary !important;
}

.color-text-disabled {
  color: $md-dark-text-disabled !important;
}

.color-text-dividers {
  color: $md-dark-text-dividers !important;
}

.color-icons-active {
  color: $md-dark-icons-active !important;
}

.color-icons-inactive {
  color: $md-dark-icons-inactive !important;
}

.color-text-primary-light {
  color: $md-light-text-primary !important;
}

.color-text-secondary-light {
  color: $md-light-text-secondary !important;
}

.color-text-disabled-light {
  color: $md-light-text-disabled !important;
}

.color-text-dividers-light {
  color: $md-light-text-dividers !important;
}

.color-icons-active-light {
  color: $md-light-icons-active !important;
}

.color-icons-inactive-light {
  color: $md-light-icons-inactive !important;
}

.background-grey {
  background-color: $gray-white;
}

.background-white {
  background-color: white;
}

.strike {
  text-decoration: line-through;
}
