@import "../mixins/isaac-responsive";

.tab-content-margin {
  margin-top: 48px;
}

.form-margin {
  padding: 0 30%;

  @include isaac-respond-max-width(lg) {
    padding: 0 20%;
  }

  @include isaac-respond-max-width(sm) {
    padding: 0 10%;
  }

  @include isaac-respond-max-width(xs) {
    padding: 0 24px;
  }
}