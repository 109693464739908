@import "../other/variables";
@import "../mixins/_isaac-responsive";

// Core variables and mixins
@import "~bootstrap-sass/assets/stylesheets/_bootstrap";

.form-control::placeholder {
  color: $text-muted;
}

.row-lg-equal-height {
  @include isaac-respond-min-width(lg) {
    display: flex;
  }
}

.row-vert-space-cols {
  > div {
    margin-top: 16px;
  }
}

pre.alert {
  max-width: 100%
}