@import "../mixins/_isaac-responsive";
@import "../other/variables";
@import '~material-shadows/material-shadows';

.dock-card-view {
  flex: 0 0 auto;
  z-index: 0;
  padding: 16px;
}

.dock-card {
  margin: 16px;
  background-color: white;
  border-radius: 2px;
  @include z-depth(2);

  .dock-card-image {
    &.dock-card-image-restrict-width {
      @include isaac-respond-max-width(md) {
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.dock-card-image-padding {
      padding: 16px 16px 0;

      @include isaac-respond-max-width(sm) {
        padding: 16px 64px 0;
      }
    }

    &.dock-card-image-fullscreen {
      cursor: zoom-in;

      &.no-image {
        cursor: inherit;
      }
    }
  }

  .dock-card-content {
    padding: 24px;

    .dock-card-title {
      display: block;
      font-size: 1.25em;
      text-align: center;

      @include isaac-respond-max-width(sm) {
        font-size: 1em;
      }
    }

    .dock-card-subtitle {
      display: block;
      text-align: center;
      font-size: 1.1em;
      color: rgba(0,0,0,0.54);
    }

    .dock-card-properties {
      display: flex;
      flex-direction: column;
      margin: 8px auto;
      max-width: 100%;

      .dock-card-property {
        display: block;
        margin: 8px 0;
        flex: 0 1 auto;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .dock-card-property-icon {
          display: inline-block;
          width: 50px;
          text-align: center;
          color: $md-dark-icons-active;
        }

        .ui-select-highlight {
          position: relative;

          &:before {
            content: '';
            z-index: -1;
            position: absolute;
            background-color: yellow;
            left: -4px;
            top: -2px;
            width: calc(100% + 8px);
            height: calc(100% + 4px);
            pointer-events: none;
          }
        }
      }
    }

    .dock-card-button {
      width: 100%;
      margin-top: auto;
    }
  }

  &.dock-card-error {
    background-color: $color-danger;
  }

  &.dock-card-warning {
    background-color: $color-warning;
  }

  &.dock-card-success {
    background-color: $color-success;
  }

  &.dock-card-info {
    background-color: $brand-secondary;
  }

  &.dock-card-no-margin {
    margin: 0;
  }

  &.dock-card-align-left {
    .dock-card-content {
      .dock-card-title,
      .dock-card-subtitle {
        text-align: left;
      }
    }
  }

  &.dock-card-flex {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    width: calc(20% - 32px);

    @include isaac-respond-max-width(lg) {
      width: calc(25% - 32px);
    }

    @include isaac-respond-max-width(md) {
      width: calc(33.3% - 32px);
    }

    @include isaac-respond-max-width(sm) {
      width: calc(50% - 32px);
    }

    @include isaac-respond-max-width(xs) {
      width: calc(100% - 32px);
    }

    .dock-card-image {
      flex: 0 0 auto;
    }

    .dock-card-content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      .dock-card-spacer {
        flex: 1 0 auto;
      }
    }
  }
}
