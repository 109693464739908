.product-category {
  .product-category-item {
    display: inline-block;

    &:after {
      content: "/";
      color: rgba(0,0,0,0.54);
      margin: 0 6px;
    }

    &:last-child:after {
      content: normal;
    }
  }
}