@import "../other/variables";
@import "../mixins/isaac-responsive";

.product-item-stock {
  padding: 12px 0;
  border-top: 1px solid $gray-lightest;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 100%;

  .product-stock-controls {
    flex: 0 0 50%;
    margin-bottom: 24px;

    .ui-select-container {
      width: 100%;
    }
  }

  .product-stock-state {
    flex: 0 0 50%;
    text-align: right;
    color: rgba(0,0,0,.57);
    font-style: italic;
    margin-bottom: 24px;

    .dock-label {
      text-align: right;
      display: block;
    }
  }

  .product-item-stock-item {
    flex: 0 0 100%;
    justify-content: space-around;
    padding: 12px;
    max-width: 100%;

    @include isaac-respond-min-width(md) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .variantForm {
      margin-bottom: 10px;
    }
  }

  .product-stock-add-size {
    flex-basis: 100%;
    text-align: center;

    .label {
      color: $gray;
      font-size: 14px;
      font-weight: normal;
      padding-left: 0;
    }

    .input {
      margin-left: 10px;
      width: 190px;
      display: inline-block;
    }
  }
}

.product-item-stock-item {
  display: flex;

  .variantForm {
    display: flex;
    align-items: center;

    .label {
      text-align: left;
      color: $gray;
      font-size: 14px;
      font-weight: normal;
      padding-left: 0;

      span {
        display: block;
        font-size: 12px;
        color: $gray-light;
      }
    }

    .stockSwitch {
      margin: 0 10px;
    }

    .stock-input {
      margin: 0 0 0 10px;
      width: 80px;
      display: inline-block;
    }

    .price-input {
      width: 100px;
      margin: 0 0 0 10px;
    }
  }

  .product-stock-item-warning-asterisk {
    display: inline-block;
    margin-left: 10px;
    background-color: transparent;
  }
}
