$tooltip-max-width: 300px;

.tooltip-inner {
	min-width: 100px;
	background-color: $gray-dark;
}

.tooltip {
  &.top .tooltip-arrow {
    border-top-color: $gray-dark;
  }
  &.right .tooltip-arrow {
    border-right-color: $gray-dark;
  }
  &.left .tooltip-arrow {
    border-left-color: $gray-dark;
  }
  &.bottom .tooltip-arrow {
    border-bottom-color: $gray-dark;
  }
}
