@import "../../sass/other/variables";
@import '../../../../node_modules/material-colors/dist/colors.scss';;

.dock-checkbox {
  display: block;
  text-align: left;
  width: 14px;
  height: 14px;

  + span {
    user-select: none;
  }

  &.inline {
    display: inline-block;
    vertical-align: sub;
    margin-right: 10px;

    + span {
      vertical-align: middle;
    }

    .dock-checkbox-outline {
      display: inline-block;
    }
  }

  &[disabled],
  &[readonly] {
    .dock-checkbox-outline {
      border-color: $gray-lighter;

      &.checked {
        background-color: $gray-lighter;
      }
    }

    + span {
      color: $md-dark-text-disabled;
    }
  }

  .dock-checkbox-outline {
    width: 14px;
    height: 14px;
    border: solid 2px $brand-secondary;
    border-radius: 2px;
    background-color: transparent;
    transition: background-color 0.2s;
    position: absolute;
    cursor: pointer;

    &.checked {
      background-color: $brand-secondary;

      .dock-checkbox-checkmark {
        opacity: 1;
      }
    }

    &.white {
      border-color: white;

      &.checked {
        background-color: white;
      }

      .dock-checkbox-checkmark {
        color: $brand-secondary;
      }
    }

    .dock-checkbox-checkmark {
      position: absolute;
      top: -2px;
      line-height: 14px;
      font-size: 10px;
      opacity: 0;
      color: white;
      transition: opacity 0.2s;
    }
  }
}
