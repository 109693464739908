@import "../../sass/mixins/_isaac-responsive";
@import "../../sass/other/variables";
@import '~material-shadows/material-shadows';

/* Specification of the Dock selection bar. Provides a general styling for all selection bars across dock */
dock-selection-bar {
  position: sticky;
  top: 0;
  z-index: 999;
}

.dock-selection-bar {
  position: relative;
  height: 56px;
  padding: 12px $grid-gutter-width / 2;
  display: flex;
  width: 100%;
  background-color: white;
  transition: background-color 0.2s, border-bottom 0.2s, border-top 0.2s, height 0.1s, padding 0.1s;
  align-items: center;
  border-bottom: 2px solid $gray-white;
  border-top: 2px solid $gray-white;

  @include isaac-respond-max-width(sm) {
    padding: 0 16px;
  }

  .dock-selection-bar-mobile-title {
    display: none;

    @include isaac-respond-max-width(xs) {
      display: initial;
      line-height: initial;
    }
  }

  .dock-selection-bar-title,
  .dock-selection-bar-mobile-title {
    margin: 4px 6px 4px 0px;
    flex: 1 0 auto;
  }

  .dock-selection-bar-title {
    @include isaac-respond-max-width(lg) {
      font-size: 16px;
    }

    @include isaac-respond-max-width(md) {
      font-size: 1em;
      line-height: 1.5;
    }

    @include isaac-respond-max-width(xs) {
      display: none;
    }
  }

  .dock-selection-bar-title-muted {
    color: rgba(255,255,255,.7);
  }

  &.selected {
    background-color: $brand-secondary;
    border-bottom: 2px solid $brand-secondary;
    border-top: 2px solid $brand-secondary;

    .dock-selection-bar-title,
    .dock-selection-bar-mobile-title {
      color: white;
    }
  }

  &.collapsed {
    overflow-y: hidden;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;
  }

  .dock-selection-bar-actions {
    margin: 4px 0px 4px 6px;
    flex: 0 0 auto;

    .dock-selection-bar-actions-text {
      display: inline-block;
      margin-right: 8px;
      color: white;

      @include isaac-respond-max-width(sm) {
        display: none;
      }

      @include isaac-respond-max-width(md) {
        display: initial;
      }

      @include isaac-respond-max-width(lg) {
        display: none;
      }
    }

    .dock-selection-bar-action {
      display: inline-block;
      margin: 0 8px;

      @include isaac-respond-max-width(lg) {
        margin-right: 0;
      }

      @include isaac-respond-max-width(md) {
        margin-right: 8px;
      }

      span {
        color: white;

        @include isaac-respond-max-width(md) {
          display: none;
        }
      }

      &:after {
        content: '|';
        margin: 0 12px;
        color: rgba(255,255,255,.5);

        @include isaac-respond-max-width(lg) {
          margin: 0 8px;
        }

        @include isaac-respond-max-width(sm) {
          display: none;
        }
      }

      &:last-child:after {
        content: none;
      }

      .dock-selection-bar-action-icon {
        color: white !important;
        margin-right: 8px;

        @include isaac-respond-max-width(md) {
          margin-right: 0;
        }

        @include isaac-respond-max-width(sm) {
          font-size: 18px;
        }
      }

      .disabled-wrapper {
        display: inline-block;
        transition: opacity 0.2s;
        opacity: 1;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

@include isaac-respond-max-width(md) {
  .dock-selection-bar {

    .dock-selection-bar-actions {

      .dock-selection-bar-action {
        span {
          display: none;
        }

        .dock-selection-bar-action-icon {
          margin-right: 0;
        }
      }
    }
  }
}

