@import '~material-colors/dist/colors.scss';

.has-tooltip::after {
  content: ' (?)';
  font-size: 0.7em;
  position: relative;
  top: -0.3em;
  color: $md-dark-icons-active;
  font-weight: bold;
}
