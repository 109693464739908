@import '~material-colors/dist/colors.scss';
@import '~font-awesome/scss/_variables';
@import '~font-awesome/scss/_mixins';
@import '../other/variables';

/* Bootstrap overwrites and styling for ui_select */
.ui-select-container {

  &.ui-select-max-width {
    max-width: 300px;
  }

  .btn {
    font-weight: normal;
  }

  .btn-default {
    background-color: white;

    &:hover {
      background-color: white;
    }
  }

  .btn-default-focus {
    background-color: white;
    outline: none;

    .btn-default {
      border-color: $brand-secondary;
    }
  }

  .glyphicon {
    @include fa-icon();
    color: rgba(0, 0, 0, 0.38);
    vertical-align: middle;

    &.glyphicon-remove:before {
      content: '\f00d';
    }
  }

  .ui-select-no-choice-text {
    padding: 3px 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, .54);
    font-style: italic;
  }

  .ui-select-no-choice-spinner {
    display: inline-block;
    position: absolute;
    right: 20px;
  }

  .ui-select-search {
    /* Overrides the inline style for input width */
    width: 100% !important;
  }
}

.ui-select-multiple.ui-select-bootstrap {
  padding: 6px 12px;

  .ui-select-match-item {
    padding: 2px 8px;
    margin: 0 6px 6px 0;
    border: none;
    color: white;
    background-color: $brand-secondary;
    transition: all 0.1s;

    &:focus,
    &:hover,
    &:active,
    &.btn-primary {
      background-color: $brand-secondary-dark;
    }
  }

  .ui-select-match-close,
  .ui-select-match .close {
    text-shadow: none;
    color: white;
    font-size: 1.2em;
    line-height: 1.2;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
}

.ui-select-bootstrap {
  .ui-select-choices-row {
    &.active.disabled > span,
    &.disabled > span {
      color: $md-dark-text-disabled;
    }
  }
  .ui-select-match-text {
    width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > .ui-select-choices {
    width: auto;
    min-width: 100%;
  }
}

.has-error .ui-select-container .btn-default-focus {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;

  .btn-default {
    border-color: #a94442;
  }
}
