@import "../other/variables";
@import "../mixins/isaac-responsive";
@import '~material-colors/dist/colors.scss';

.pagination-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $grid-gutter-width / 2 0;

  &.pagination-bar-small-padding {
    padding: $grid-gutter-width / 4 0;
  }

  @include isaac-respond-max-width(xs) {
    flex-direction: column;
    align-items: stretch;

    .pagination-bar-options {
      flex: 0 0 auto !important;
    }
  }

  .pagination-bar-options {
    padding: 0 $grid-gutter-width / 2;
    align-items: center;

    @include isaac-respond-max-width(xs) {
      text-align: center;
      padding-top: $grid-gutter-width / 4;
      padding-bottom: $grid-gutter-width / 4;
    }
  }

  .pagination-bar-options-left,
  .pagination-bar-options-right {
    flex: 0 0 200px;
  }

  .pagination-bar-options-middle {
    flex: 0 1 100%;
    text-align: center;

    @include isaac-respond-max-width(xs) {
      order: 1
    }
  }
}

.pagination {
  margin: 0;

  > li > a {
    border: solid 2px transparent;
    border-radius: 4px;
    margin: 0 6px;

    &:hover {
      background-color: $md-dark-text-dividers;
      border-color: transparent;
    }

    &:focus {
      background-color: transparent;
      border-color: $brand-secondary;
      outline: none;
    }
  }

  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    background-color: $brand-secondary;
    border-color: $brand-secondary;
  }

  .pagination-prev, .pagination-next {
    a:before {
      font-family: "FontAwesome";
      color: $brand-secondary;
      font-size: 0.7em;
    }

    &.disabled {
      a {
        border-color: transparent;

        &:before {
          color: $md-dark-text-disabled
        }
      }
    }
  }

  .pagination-prev a:before {
    content:"\f053";
  }

  .pagination-next a:before {
    content:"\f054";
  }
}
