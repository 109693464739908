@import "../other/variables";

$default-spinner-size: 48px;
$default-spinner-margin: 16px;
$default-spinner-border-width: 5px;

$xs-spinner-size: 16px;
$xs-spinner-margin: 4px;
$xs-spinner-border-width: 2px;

.spinner-flat,
.spinner-flat:after {
  border-radius: 50%;
  width: $default-spinner-size;
  height: $default-spinner-size;
}

.spinner-flat.spinner-xs,
.spinner-flat.spinner-xs:after {
  width: $xs-spinner-size;
  height: $xs-spinner-size;
}

.spinner-flat {
  position: relative;
  border: $default-spinner-border-width solid rgba(255, 255, 255, 0.2);
  border-left-color: #ffffff;
  transform: translateZ(0);
  animation: spinner-flat-rotate 1.1s infinite linear;
  opacity: 1;
  transition: opacity 0.2s;
  margin: $default-spinner-margin;

  &.inactive {
    opacity: 0;
  }

  &.spinner-invert {
    border-color: #ffffff;
    border-left-color: rgba(255, 255, 255, 0);
  }

  &.spinner-xs {
    border-width: $xs-spinner-border-width;
    margin: $xs-spinner-margin;
  }

  &.spinner-secondary {
    border-color: rgba($brand-secondary, 0.2);
    border-left-color: $brand-secondary;

    &.spinner-invert {
      border-color: $brand-secondary;
      border-left-color: rgba($brand-secondary, 0);
    }
  }
}

.spinner-centered {
  position: absolute;
  margin: 0 !important;

  top: calc(50% - #{ $default-spinner-size } / 2);
  left: calc(50% - #{ $default-spinner-size } / 2);

  &.spinner-xs {
    top: calc(50% - #{ $xs-spinner-size } / 2);
    left: calc(50% - #{ $xs-spinner-size } / 2);
  }
}

.spinner-inline {
  display: inline-block;
  vertical-align: middle;
}

@keyframes spinner-flat-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
