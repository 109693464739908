@import '../other/variables';
@import "../mixins/_isaac-responsive";

.status-icon {
  padding: 0 8px;
  border-radius: 4px;
  color: white;
  background-color: $brand-secondary;

  &:before {
    vertical-align: middle;
    line-height: 24px;
  }

  &.status-rush {
    background-color: $color-rush;
  }

  &.status-received {
    background-color: $color-received;
  }

  &.status-discount {
    background-color: $color-discount;
  }

  &.status-deficient {
    background-color: $color-deficient;
  }

  &.status-deleted {
    background-color: $color-deleted;
  }

  &.status-error {
    background-color: $color-error;
  }

  &.status-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@include isaac-respond-max-width(xs) {
  .status-icon.status-icon-responsive-label {
    white-space: nowrap;
    padding: 3px 12px;
    margin: 0 4px 4px;
    border-radius: 50px;

    &:before {
      margin-right: 4px;
    }

    &:after {
      content: attr(uib-tooltip); // Show the text from the tooltip
      font-family: $font-family-sans-serif;
      text-transform: uppercase;
      font-size: 0.8em;
      vertical-align: middle;
    }

    &.status-icon-action {
      border-radius: 4px;
    }

    // Disable the tooltips since we're already showing the full tooltip text
    & + .tooltip {
      display: none;
    }
  }
}
