@import "../mixins/isaac-responsive";
@import "../other/variables";

.stock-form {
  text-align: center;

  .size-edit {
    margin-top: 48px;
  }

  .stock-edit {
    display: flex;
    margin-top: 48px;
    flex-wrap: wrap;

    .stock-edit-title,
    .stock-edit-message {
      flex: 0 0 100%;
    }

    .stock-edit-item {
      display: flex;
      flex: 0 0 50%;
      padding: 8px 12px;

      @include isaac-respond-max-width(lg) {
        flex: 0 0 100%;
      }

      @include isaac-respond-max-width(sm) {
        flex: 0 0 100%;
      }

      .stock-edit-item-label {
        flex: 1 1 20%;
        font-weight: bold;
        line-height: 35px;
        padding-right: 8px;
        text-align: right;
      }

      .stock-edit-item-price {
        flex: 1 1 40%;
        max-width: 40%;
        padding-right: 8px;
      }

      .stock-edit-item-controls {
        display: flex;
        flex: 1 1 40%;

        .input-group-btn {
          width: auto;
        }

        .stock-edit-item-controls-button {
          flex: 0 0 auto;
        }

        .stock-edit-item-controls-input {
          flex: 1 1 auto;
        }
      }
    }
  }

  .stock-form-actions {
    margin-top: 48px;
    display: flex;
    justify-content: space-around;

    [class*=spinner-] {
      margin-left: 8px;
    }

    @include isaac-respond-max-width(sm) {
      flex-direction: column;
      height: 100px;
    }
  }
}

.stock-ean-form-row-wrapper {
  display: flex;
  align-items: baseline;

  @include isaac-respond-min-width(lg) {
    .dock-table .dock-table-row &.dock-table-column {
      padding-top: 0;
    }
  }

  @include isaac-respond-max-width(xs) {
    display: block;
  }

  .stock-ean-form-row-label {
    white-space: nowrap;
    margin-right: 8px;

    @include isaac-respond-max-width(xs) {
      display: none;
    }
  }
}

.input-group-addon {
  &.input-group-addon-primary {
    background-color: $brand-secondary;
    color: white;
    border: none;
  }
}

//form
.form-control {
  font-size: 14px;

  @include isaac-respond-min-width(sm) {
    font-size: 14px;
  }

  &.form-control-25 {
    width:25%;
  }

  &.form-control-50 {
    width:50%;
  }

  &.form-control-75 {
    width:75%;
  }
}
label {
	font-weight:normal;
}

.control-label {
	font-weight: bold;
	@include isaac-respond-min-width(md) {
		font-weight: normal;
	}
}

form.hasChanges {
	border: 1px solid #00a8e2;
}

.addedit-form {
  i {
    margin-right: 5px;
    padding-top: 10px;
    color: $gray-light;
  }
}

.has-error .btn-default-focus {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
}
