@import '~material-colors/dist/colors.scss';
@import '~material-shadows/material-shadows';
@import '../../sass/other/variables';
@import '../../sass/mixins/isaac-responsive';

.dock-header {
  background-color: $gray-white;
  border-bottom: solid 1px $pre-border-color;
  &.dock-header-borderless {
    border: none;
  }

  .dock-header-content {
    display: flex;

    @include isaac-respond-max-width(md) {
      flex-wrap: wrap;
    }
  }

  .dock-breadcrumbs {
    flex: 0 0 auto;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 48px;

    .dock-breadcrumb {
      line-height: $logo-height;
      text-decoration: none;
      font-size: 1.3em;

      &:hover,
      &:active {
        text-decoration: none;
      }

      &:before {
        font-family: FontAwesome;
        content: '\f105';
        color: $md-dark-icons-inactive;
        margin: 0 8px;
      }

      &:first-of-type:before {
        content: none;
      }

      i {
        color: $md-dark-icons-active;
      }

      span {
        color: $md-dark-text-secondary;
      }
    }
  }

  .dock-tabs {
    flex: 1 1 auto;
    height: $logo-height;
    display: flex;
    align-items: flex-end;
    position: relative;
    top: 1px;
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;

    @include isaac-respond-max-width(md) {
      order: 1;
    }

    .dock-tab {
      color: $md-dark-text-secondary;
      display: inline-block;
      padding: 12px 24px;
      margin: 0 8px;
      border-radius: 4px 4px 0 0;
      border: solid 1px rgba(204,204,204,0);
      border-bottom: none;
      transition: border-color 0.2s, background-color 0.2s;
      white-space: nowrap;

      &:hover {
        border-color: rgba(204,204,204,1);
      }

      &.active {
        background-color: white;
        border-color: rgba(204,204,204,1);
      }

      @include isaac-respond-max-width(xs) {
        flex: 1 1 auto;
        text-align: center;
      }
    }
  }

  ng-transclude {
    flex: 1 1 20%;

    @include isaac-respond-max-width(lg) {
      flex-basis: 50%
    }

    @include isaac-respond-max-width(md) {
      flex-basis: 70%;
    }

    @include isaac-respond-max-width(xs) {
      flex: 0 0 100%;
    }
  }
}
