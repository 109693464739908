@import "../mixins/isaac-responsive";

.panel {
  margin: 0;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 0;

  .text {
    padding-top: 5px;
  }

  .checkbox {
    height: auto;
    margin: 0;

    input {
      margin: 10px 0 0 10px;

      @include isaac-respond-min-width(sm) {
        margin: 10px 0 0 15px;
      }
    }
  }

  .removed {
    color: #990000;

    h4 {
      color: #990000;
    }

    .img-responsive {
      border-color: #990000;
    }
  }

  .panel-body {
    padding: 0;
  }

  .panel-footer {
    background: none repeat scroll 0 0 transparent;
    border: medium none;
    padding: 10px 0 0 0;
    border-top: 1px dashed #ddd;
  }

  .stockLabel {
    text-align: right;
    margin: 6px 0 0 0;
  }

  .stockSwitch {
    margin: 8px 0 0 0;
  }
}

.panel-matcher {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;

  &:hover, &:active {
    background: #fafafa;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.panels-filled {
  .panel {
    padding: 0;
    .panel-heading {
      border: 1px solid #ddd;
    }

    .panel-body {
      padding: 10px 15px;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
  }
}