@import "../mixins/_isaac-responsive";

// We use "!important" since these are one-property classes, so they are only used to override
.no-padding {
  padding: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-height {
  height: 0 !important;
}

.placeholder {
  opacity: 0;
  pointer-events: none;
}

.hide {
  display: none;
}

.sticky {
  position: sticky;

  &.sticky-from-lg {
    position: inherit;

    @include isaac-respond-min-width(lg) {
      position: sticky;
    }
  }

  &.sticky-to-lg {
    position: sticky;

    @include isaac-respond-min-width(lg) {
      position: inherit;
    }
  }
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}