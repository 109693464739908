@import '~material-colors/dist/colors.scss';
@import '~material-shadows/material-shadows';
@import '../mixins/gradients';

.dock-product-color-indicator {
  display: inline-block;
  vertical-align: middle;

  &:before {
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    border: white 3px solid;
    @include z-depth-2dp();
  }

  &.color-beige {
    &:before {
      background-color: #D4A281;
    }
  }

  &.color-blauw {
    &:before {
      background-color: #0077C0;
    }
  }

  &.color-bruin {
    &:before {
      background-color: #7E4C2B;
    }
  }

  &.color-dierenprint {
    &:before {
      background-image: url('../images/leopard-print.jpg');
      filter: saturate(50%);
    }
  }

  &.color-divers {
    &:before {
      @include gradient-stops(#1ABC9C, #34495E, #CA2C68, #F1C40F, #C0392B);
      filter: saturate(70%);
    }
  }

  &.color-geel {
    &:before {
      background-color: #F9BF3B;
    }
  }

  &.color-goud,
  &.color-oranje {
    &:before {
      background-color: #F5AB35;
    }
  }

  &.color-grijs {
    &:before {
      background-color: #505050;
    }
  }

  &.color-groen {
    &:before {
      background-color: #0EAC51;
    }
  }

  &.color-metalic {
    &:before {
      background-image: -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0)   6%, hsla(0,0%,100%, .1) 7.5%),
                        -webkit-repeating-linear-gradient(left, hsla(0,0%,  0%,0) 0%, hsla(0,0%,  0%,0)   4%, hsla(0,0%,  0%,.03) 4.5%),
                        -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 1.2%, hsla(0,0%,100%,.15) 2.2%),

                        linear-gradient(180deg, hsl(0,0%,78%)  0%,
                                        hsl(0,0%,90%) 47%,
                                        hsl(0,0%,78%) 53%,
                                        hsl(0,0%,70%)100%);
    }
  }

  &.color-neutraal {
    &:before {
      background-color: #D8D0B9;
    }
  }

  &.color-paars {
    &:before {
      background-color: #7E349D;
    }
  }

  &.color-rood {
    &:before {
      background-color: #E74C3C;
    }
  }

  &.color-roze {
    &:before {
      background-color: #DA3C78;
    }
  }

  &.color-taupe {
    &:before {
      background-color: #EAB897;
    }
  }

  &.color-wit {
    &:before {
      background-color: #FFFFFF;
    }
  }

  &.color-zilver {
    &:before {
      background-color: #BCCAD9;
    }
  }

  &.color-zwart {
    &:before {
      background-color: #000000;
    }
  }
}
