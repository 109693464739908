/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-keyword or $is-angle;
}

@mixin gradient-stops($direction, $colors...) {
  @if is-direction($direction) == false {
    $colors: join($direction, $colors);
    $direction: to right;
  }

  $percentage-step: 100% / length($colors);
  $gradient: ();

  @for $index from 1 through length($colors) {
    $color: nth($colors, $index);
    $gradient: append($gradient, $color ($index - 1) * $percentage-step, comma);
    $gradient: append($gradient, $color $index * $percentage-step, comma);
  }

  background: linear-gradient($direction, $gradient);
}
