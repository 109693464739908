@import "../other/variables";
@import "../mixins/isaac-responsive";

/*html,
body,
body div[ng-view],
body div[ng-view] > div,
body div[ng-view] > div > div,
.container-fluid {
  height: 100%;

  &.initial-container {
    height: auto;
  }
}*/
html, body {
  height: 100%;
}

body {
  display: flex;
}


body > div > div.modal-dialog,
body > div > div > div.modal-content {
  height: auto;
}

.full-height,
.message-container,
.container-fluid {
  display: flex;
  flex-direction: column;
}

.full-height {
  flex: 1 0 auto;
}

.message-container {
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

[ng-click] {
  cursor: pointer;
}

a > img {
    cursor: pointer;
}

.img-responsive {
    background: none repeat scroll 0 0 #fff;
    //border: 1px solid #eee;
    //padding: 10px;
}
.container-fluid {
    position: relative;
    z-index: 0;
    margin-left: $menu-small-width;
	width: calc(100% - #{$menu-small-width});

	@include isaac-respond-min-width(md) {
		margin-left: $menu-wide-width;
		width: calc(100% - #{$menu-wide-width});
	}

    &.nav-wide {
        margin-left: $menu-wide-width;
        width: calc(100% - #{$menu-wide-width});
    }

	h1 {
		font-size: 20px;
		margin: 18px 0 10px;
		padding: 0;
		text-transform: uppercase;
	}

    &.container-fluid-full-width {
      margin-left: 0;
      width: 100%;
    }
}

.list-group {
	.list-group-header {
		font-weight: bold;
		cursor: default;
		&:hover {
			background: #ffffff;
		}
		select {
			float: right;
			font-weight: normal;
			border: 1px solid $brand-primary;
		}
	}
	.list-group-divider {
		height: 2px;
		overflow: hidden;
		border-bottom: 0;
		padding: 0;
	}
}

.main-header {
    min-height: 55px;
    display: flex;
    > div {
        width: auto;
    }

    .main-header-column {
      padding: 0 6px;
    }

    .main-header-title {
        flex-grow: 2;
    }
    .main-header-pagination {
        flex-shrink: 0;
    }
    .main-header-search {
        @include isaac-respond-max-width(xs) {
            flex-basis: 100%;
        }
        min-width: 145px;
        max-width: 500px;
        .search {
        }
    }
    &.page-orders {
        justify-content: space-between;
        flex-wrap: wrap;
        flex: 0 0 auto;
        .main-header-search {
            flex-basis: 30%;
        }
    }
    &.page-products {
        justify-content: space-between;
        flex: 0 0 auto;
        @include isaac-respond-max-width(xs) {
            flex-wrap: wrap;
            .main-header-title {
                margin-right: auto;
            }
            .main-header-share-product {
                margin-left: auto;
                margin-bottom: 10px;
            }
        }
    }
}
.loading {
	background: none repeat scroll 0 0 #eee;
    border-radius: 25px;
    margin: 100px auto;
    padding: 4px 0;
    text-align: center;
    width: 200px;
}
.modal {
	background: rgba(0,0,0,0.75);
	.modal-content {
		padding: 12px 24px;
	}
}
.margin-bottom {
	margin-bottom: 45px;
}
.actions {
	padding: 20px 0;
	color: #000;
	overflow:hidden;
	border-bottom:1px solid #ccc;
	text-align:center;
	select {
		color: $gray-darker;
	}
    .actions-execute {
        width:100%;
        @include isaac-respond-min-width(sm) {
            width:auto;
        }
        span {
            width:50%;
            float:left;
            text-align: left;
            &.actions-execute-select {
                width: 60%;
                select {
                    height: 36px;
                    padding-left: 10px;
                    display: inline;
                    width: 100%;
                }
            }
            &.actions-execute-button {
                width: 40%;
                button {
                    margin-top: 0;
                    margin-left: 10px;
                    width:auto;
                    @include isaac-respond-min-width(sm) {
                        width:100%;
                    }
                }
            }
        }
    }
    .actions-select-all {
        width:100%;
        margin-bottom:5px;
        @include isaac-respond-min-width(sm) {
            width:auto;
            margin: 0 0 0 5px;
        }

        label {
            padding-bottom:5px;
            width:100%;
            @include isaac-respond-min-width(sm) {
                width:auto;
                padding:5px 23px 5px 0;
                margin:0 10px 0 0;
                border-bottom:medium none;
                border-right:1px solid #ddd;
            }
            &:last-child {
                border-right:none;
            }
            input {
                margin-right: 4px;
            }
        }
    }
    .sorting {
        border-top: 1px solid #ccc;
        margin-top: 20px;
        padding: 20px 0 0;
        @include isaac-respond-min-width(lg) {
            padding-top:0;
            margin-top:0;
            border:medium none;
        }
    }
	select {
		padding-left:10px;
	}
	span {
		width:100%;
		display:block;
		@include isaac-respond-min-width(sm) {
			display:inline;
			width:auto;
		}
	}
	.btn {
		margin-top:10px;
		@include isaac-respond-min-width(sm) {
			margin-top:0;
		}
	}
}
.filters {
	border-bottom:1px solid #ccc;
	.btn {
		width:100%;
		@include isaac-respond-min-width(sm) {
			width:auto;
		}
	}
    .showSales {
        padding:0 10px;
        width:100%;
        @include isaac-respond-min-width(sm) {
            width:auto;
            padding:0 10px 0 0;
        }

        label {
            border: 1px solid #ccc;
            border-radius: 3px;
            line-height: 19px;
            padding: 6px 10px;
            width: 100%;
            margin-bottom: 15px;
            @include isaac-respond-min-width(sm) {
                width: auto;
            }
        }
    }

}
.badge {
	border-radius: 4px;
	border: 0;
    color: $gray-white;
	background: $brand-secondary;
    min-width: 20px;
    text-align: center;
    padding: 5px;

    @include isaac-respond-max-width(sm) {
        display: none;
    }
}

.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
	border-color:#fff;
	color: $gray-darkest;
	background-color:transparent;
}
.search {
	margin: 10px 0 0;
}

.alert-info {
    text-align: center;
}

/* animations */
.page-load, .page-load.loading-true {
	opacity: 0;
	transition: opacity 0.3s;
}
.page-load.loading-false {
	opacity: 1;
}
.form-group:not(.has-error) {
    .has-error {
        display: none;
    }
}
