@import "../../sass/other/variables";
@import "../../sass/mixins/isaac-responsive";

.dock-searchable {
  .search-input {
    margin-top: 8px;

    @include isaac-respond-max-width(md) {
      display: none;
    }
  }
}
