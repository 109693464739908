@import '~material-shadows/material-shadows';
@import '../other/variables';
@import '../mixins/isaac-responsive';

.dock-controls {
  @include z-depth(2);
  background: white;
  z-index: 99;

  &.dock-controls-layer {
    z-index: 100;
    box-shadow: none;
  }

  &.dock-controls-linking {
    top: 0;

    .dock-controls-actions {
      height: 79px;

      @include isaac-respond-max-width(sm) {
        height: auto;
      }
    }
  }

  .dock-controls-actions {
    transition: height 0.3s $animation-easing, opacity 0.2s 0.3s;
  }

  &.dock-controls-collapsed {
    .dock-controls-actions {
      height: 4px;
      opacity: 0;
      transition: height 0.3s 0.2s $animation-easing, opacity 0.2s;
    }
  }
}
