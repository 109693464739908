@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid';
@import '~material-shadows/material-shadows';
@import '~material-colors/dist/colors.scss';
@import '../other/variables';
@import '../mixins/_isaac-responsive';

$form-row-gutter: $grid-gutter-width / 4;

.dock-form {
  section,
  aside .dock-form-aside {
    margin: 16px 0;

    h3 {
      margin-top: $line-height-computed / 2;
    }
  }

  aside {
    @include make-xs-column(24);

    @include isaac-respond-max-width(md) {
      padding-left: 0;
      padding-right: 0;
    }

    .dock-form-aside {
      padding: $grid-gutter-width / 2;
    }
  }

  section {
    @include make-xs-column(24);
    padding-top: $grid-gutter-width / 2 - $form-row-gutter / 2;
    padding-bottom: $grid-gutter-width / 2 - $form-row-gutter / 2;

    textarea {
      resize: vertical;

      &[autoheight] {
        resize: none;
      }
    }

    /* Prevent bootstrap from adding make-lg-column properties to labels inside a form-group */
    .dock-form-input-row > label {
      font-weight: bold;
      color: $md-dark-text-secondary;

      small {
        font-weight: normal;
      }

      @include make-sm-column(8);
    }

    .dock-form-input-row {
      @include make-row();
      margin-top: $form-row-gutter;
      margin-bottom: $form-row-gutter;
    }

    .dock-form-input-small {
      @include make-sm-column(7);
    }

    .dock-form-input {
      @include make-sm-column(12);
    }

    .dock-form-input-large {
       @include make-sm-column(16);
     }

    /* Margins for group inputs are half the normal form-row-gutter since flex contexts don't have collapsing margins */
    .dock-form-group {
      display: flex;
      padding-left: $grid-gutter-width / 2 - $form-row-gutter / 2;
      padding-right: $grid-gutter-width / 2 - $form-row-gutter / 2;

      div {
        margin-left: $form-row-gutter / 2;
        margin-right: $form-row-gutter / 2;
      }
    }

    .dock-form-section-overlay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: rgba(255,255,255,0.8);
      text-shadow: 0 0 6px #fff;
      opacity: 0;
      pointer-events: none;
      transition: opacity 100ms;

      &.visible {
        opacity: 1;
        pointer-events: all;
      }

      &.dock-form-section-overlay-loading {
        animation-name: loading-opacity;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }

      .dock-form-section-overlay-progress {
        position: absolute;
        bottom: 0;
      }
    }

    .row > p,
    .row > h3 {
      @include make-xs-column(24);
    }

    .row > h3 {
      z-index: 2;
    }

    input[readonly],
    textarea[readonly],
    dock-filter-bar-item[readonly] .form-control {
      background: none;
      border: none;
      box-shadow: none;
      opacity: inherit;
      cursor: inherit;
      user-select: inherit;
      padding: 0;
      height: inherit;
    }

    textarea[readonly] {
      resize: none;
    }

    dock-filter-bar-item[readonly] .form-control {
      i {
        display: none;
      }
    }
  }

  .dock-form-actions {
    @include isaac-respond-min-width(lg) {
      top: 0;
    }
  }

  .dock-form-actions-container {
    z-index: 999;
    bottom: -18px;

    @include isaac-respond-min-width(lg) {
      bottom: inherit;
    }
  }
}

@keyframes loading-opacity {
  from { background-color: rgba(255,255,255,0.95); }
  to { background-color: rgba(255,255,255,0.7); }
}