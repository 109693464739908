@import "../mixins/isaac-responsive";
@import "../other/variables";
@import '~material-shadows/material-shadows';

nav#nav {
  width: $menu-small-width;
  overflow: hidden;
  background: none repeat scroll 0 0 $gray;
  bottom: 0;
  color: #ffffff;
  display: block;
  padding-top: 0;
  position: fixed;
  top: 0;
  font-size: 0;
  z-index: 1;

  @include isaac-respond-min-width(md) {
    width: $menu-wide-width;
    font-size: 16px;
  }

  i {
    font-size: 20px;
    width: 30px;
  }

  .nav-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    margin: 0;
  }

  #logo-ws {
    min-height: $logo-height;
    display: none;
    background-color: $brand-primary;

    @include isaac-respond-min-width(md) {
      display: block;
    }

    svg {
      width: calc(100% - 10px);
      padding: 20px 5px 10px;
      height: auto;
      max-height: 45px;
      margin: 0 auto;
      display: block;

      path {
        fill: $gray-white;
      }
    }

    > div {
      color: $gray-white;
    }
  }

  .retailer-logo {
    margin-top: auto;
    display: none;

    @include isaac-respond-min-width(md) {
      display: block;
    }

    background-color: $gray-darkest;
    padding: 10px 15px;
    min-height: 45px;

    i {
      padding-right: 5px;
    }
  }

  .language-select {
    min-height: 45px;

    a {
      background-color: $gray;
      width: 100%;
      display: block;
      padding: 10px 15px;
      color: $gray-lighter;
      text-decoration: none;

      i {
        padding-right: 5px;
      }

      &:hover {
        background: $gray-dark;
        color: $gray-white;
      }
    }

    select {
      background-color: $gray-dark;
      border: 1px solid $gray-darker;
      width: 100%;
      display: block;
      padding: 5px;
    }
  }

  .logout-button {
    display: block;
    color: $gray-lighter;
    text-align: left;
    text-decoration: none;
    padding: 10px 15px;
    min-height: 45px;

    i {
      padding-right: 5px;
    }

    &:hover {
      background: $gray-dark;
      color: $gray-white;
    }
  }

  .list-group {
    .list-group-item {
      background: transparent;
      border: none;
      color: $gray-lighter;
      padding: 10px 0;
      text-align: center;

      @include isaac-respond-min-width(md) {
        line-height: 20px;
        padding: 10px 15px;
        text-align: left;
      }

      &.sub-item {
        padding-left: 25px;
      }

      .badge {
        border: 0;
        background-color: $brand-secondary;
        color: $gray-white;
        min-width: 20px;
        text-align: center;
        vertical-align: middle;
        float: none;

        @include isaac-respond-min-width(md) {
          float: right;
        }
      }

      &.active {
        background: $gray-darker;
        color: $gray-white;

        &:hover {
          background: $gray-dark;
        }
      }

      &:hover {
        background: $gray-dark;
        color: $gray-white;
      }

      &:last-child {
        border-radius: 0;
      }

      &.info {
        color: $gray-light;

        &:hover {
          background: none;
          color: $gray-light;
        }
      }
    }

    .menu-fill-spacer {
      margin-top: auto;
    }

    .list-group-divider {
      height: 40px;
      display: block;
    }
  }
}

.nav-tabs {
  border-bottom: 1px solid #ccc;
  background-color: $gray-white;
  padding-top: 20px;

  li {
    margin-right: 5px;
    &:first-child {
      margin-left: 20px;
    }

    a {
      line-height: 1.8;

      &:hover {
        background-color: transparent;
      }
    }

    &.active {
      a {
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
      }

      .badge {
        border-radius: 4px;
      }
    }
  }

  .badge {
    border-radius: 4px;
    padding: 5px;
  }
  @include isaac-respond-max-width(xs) {
    li {
      width: 100%;
      margin-bottom: 5px;
      padding: 0 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        padding: 5px 15px;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;

        &:hover {
          border: 1px solid $gray-lightest;
        }
      }

      &.active {
        a {
          border: 1px solid #ccc;

          &:hover {
            border: 1px solid $gray-lightest;
          }
        }
      }
    }

    .badge {
      border-radius: 4px;
    }
  }
}

// Make exceptions not-restyled tabs
.nav-top-margin {
  margin-top: 20px;
}

@include isaac-respond-max-width(xs) {
  .nav-tabs {
    margin-left: 0;
    padding: 20px 0;

    & > li {
      margin-right: 0;
    }
  }
}
