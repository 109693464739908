//@import "../mixins/_isaac-responsive";
@import "../other/variables";
//@import '~material-shadows/material-shadows';

.dock-link-sort {
  margin: 8px ($grid-gutter-width / 2) 0;
  display: flex;
  align-items: flex-end;
  color: rgba(0,0,0,0.54);

  .dock-label {
    margin-bottom: 0;
    margin-right: 24px;
  }
}
