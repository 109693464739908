@import "../../sass/other/variables";

.dock-sortable {
  transition: color 0.2s;
  padding-right: 6px;
  white-space: nowrap;
  display: inline-block;
  user-select: none;

  &.dock-sortable-active {
    color: rgba(0,0,0,0.87);
  }

  span {
    vertical-align: middle;
  }

  .dock-sortable-icon {
    font-size: 0.7em;
    color: rgba(0,0,0,0.54);
    opacity: 0;
    transform: rotate(0deg);
    transition: all 0.2s;

    &.reverse {
      transform: rotate(180deg);
    }

    &.visible {
      opacity: 1;
    }
  }
}
