@import '~material-colors/dist/colors.scss';
@import '../other/variables';

$upload-blur: blur(10px);
$upload-transition: all 0.2s;

.upload-drop {
  position: relative;
  overflow: hidden;
  margin-left: -$grid-gutter-width / 2;
  margin-right: -$grid-gutter-width / 2;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $brand-secondary-dark;
    pointer-events: none;
    opacity: 0;
    transition: $upload-transition;
  }

  &.backdrop-active {
    &:after {
      pointer-events: auto;
      opacity: 0.7;
    }

    .upload-drop-blur {
      filter: $upload-blur;
    }
  }

  &.backdrop-error:after {
    background-color: $md-red-900;
  }

  &.backdrop-warning:after {
    background-color: $md-orange-900;
  }

  &.backdrop-success:after {
    background-color: $md-green-900;
  }

  .upload-drop-blur {
    filter: blur(0px);
    transition: $upload-transition;
  }

  .upload-drop-progress {
    position: relative;
    width: 70%;
    max-width: 600px;
    height: 30px;
    margin: 24px 0;
    border: solid transparent 4px;
    outline: solid white 4px;

    .upload-drop-progress-inner {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: white;
      transition: width 0.2s;
    }
  }

  .upload-drop-error-list {
    max-width: 60%;
    list-style-type: none;
    font-weight: bold;
    max-height: 60%;
    overflow: auto;
    margin: 0.5em;
    display: block;

    li {
      padding: 0.2em 0;
    }
  }
}
