.modal-body {
  padding: 10px 0 0 0;
}

.modal-close {
  position: absolute;
  right: 16px;
  top: 12px;
  z-index: 1;
}

.modal-buttons {
  margin-bottom: 24px;
}

.modal-content,
.modal-dialog {
  overflow-y: visible;
}
