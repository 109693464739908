@import "../mixins/_isaac-responsive";
@import "../other/variables";
@import '~material-shadows/material-shadows';

/* Specification of Dock tables. Dock tables define the general styling of all tables inside Dock */
.dock-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: $grid-gutter-width / 4 0;

  &.dock-table-auto-size {
    width: auto;
  }

  &.dock-table-margin {
    margin-top: $grid-gutter-width / 2;
    margin-bottom: $grid-gutter-width / 2;
  }

  &.dock-table-small-padding-left {
    .dock-table-row,
    .dock-table-inline-row {
      padding-left: $grid-gutter-width / 4;

      @include isaac-respond-max-width(xs) {
        padding-left: 0;
      }
    }
  }

  &.dock-table-small-padding-right {
    .dock-table-row,
    .dock-table-inline-row {
      padding-right: $grid-gutter-width / 4;

      @include isaac-respond-max-width(xs) {
        padding-right: 0;
      }
    }
  }

  &.dock-table-inline {
    .dock-table-row {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .dock-table-row,
  .dock-table-inline-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px $grid-gutter-width / 2;
    max-width: 100%;
    position: relative;
    opacity: 1;
    transition: opacity 0.2s;

    > .dock-table-column {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    > .dock-table-column-group:first-child > .dock-table-column:first-child {
      margin-left: 0
    }

    > .dock-table-column-group:last-child > .dock-table-column:last-child {
      margin-right: 0
    }

    &:hover {
      background-color: rgba(0,0,0,0.02);
    }

    &.dock-table-row-placeholder:hover {
      background-color: transparent
    }

    &.dock-table-child-row {
      border-left: solid 10px $brand-secondary;
      border-right: solid 10px $brand-secondary;
      padding: 2px 16px 0 16px;

      &:hover {
        background-color: inherit;
      }

      @include isaac-respond-max-width(xs) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.dock-table-row-expanded {
      background-color: rgba(0,0,0,0.05);
      @include z-depth(2);
    }

    &.dock-table-row-loading {
      opacity: 0.5;
    }
  }

  .dock-table-row {
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0);

    &.dock-table-row-disabled {
      opacity: 0.5;
    }

    &.dock-table-row-deleted {
      color: $color-deleted;
    }

    .dock-table-column-group {
      display: flex;
      justify-content: inherit;
      align-items: inherit;
    }

    .dock-table-column-group-layer {
      flex: 0 1 100%;
      display: flex;
      flex-direction: column;
    }

    .dock-table-column {
      margin: 0 6px;
      padding: 6px 0;
    }

    .dock-table-column,
    .dock-table-column-group {
      flex: 0 1 100%;

      &.dock-table-column-icon {
        flex: 0 0 28px;
        text-align: center;
        padding: 0;

        &[ng-click] {
          cursor: pointer;
        }
      }

      &.dock-table-column-img {
        flex: 0 0 64px;
        padding: 0 16px;
      }

      &.dock-table-column-align-right {
        text-align: right;
      }

      &.dock-table-column-align-bottom {
        align-self: flex-end;
      }

      &.dock-table-column-placeholder {
        padding: 0;
        opacity: 0;
        pointer-events: none;
      }

      &.dock-table-column-check {
        flex: 0 0 18px;
        &.is-magento-1 {
          margin-right: 32px;
        }
        &.is-magento-2 {
          margin-left: 32px;
        }
      }

      /* When using this class, make sure all elements in the column are of equal size, else alignment will break. */
      &.dock-table-column-fixed {
        flex: 0 0 auto;
        padding: 0;
      }
      
      &.dock-table-column-fixed-padded {
        flex: 0 0 auto;
      }

      &.dock-table-column-smaller {
        flex-basis: 70%;
      }

      &.dock-table-column-small {
        flex-basis: 50%;
      }

      &.dock-table-column-extra-small {
        flex-basis: 30%;
      }
    }

    &.dock-table-progress {
      padding: 0;
      border-bottom: none;
    }

    &.dock-table-selection-indicator {
      &:before {
        content: '';
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        transition: width 0.2s;
        background-color: $brand-secondary;
      }

      &.selected:before {
        width: 10px;
      }
    }

    &.dock-table-header {
      align-items: flex-start;

      .dock-table-column {
        color: rgba(0,0,0,0.54);

        &.dock-table-header-no-styling {
          color: inherit;
        }
      }

      &:hover {
        background-color: inherit;
      }
    }

    &.dock-table-inline-rows {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 !important;


      &:hover {
        background-color: inherit;
      }

      .dock-table-inline-row {
        flex: 0 0 100%;

      }
    }
  }

  .dock-table-row:last-child {
    border-bottom: none;
  }

  .dock-message {
    flex: 1 0 auto;
  }
}

.dock-card.dock-table {
  width: auto;
}

@include isaac-respond-max-width(md) {
  .dock-table {
    font-size: 90%;
  }

  .dock-table-column-group {
    flex-wrap: wrap;
  }
}

@include isaac-respond-max-width(xs) {
  .dock-table {
    font-size: 100%;

    .dock-table-row,
    .dock-table-inline-row {
      flex-wrap: wrap;
      padding: 16px 0;
      justify-content: center;
    }

    .dock-table-row {
      .dock-table-column {
        margin: 4px 0;

        &:not(.dock-table-column-icon) {
          text-align: center;

          > div {
            margin-left: auto;
            margin-right: auto;
          }

          &:before {
            content: " ";
          }
        }

        &[data-label] {
          text-align: left;

          > div {
            display: inline-block;
            vertical-align: middle;
            margin-left: 0;
            width: 49%;
          }

          &:before {
            display: inline-block;
            width: 50%;
            padding-right: 6px;
            text-align: right;
            content: attr(data-label);
            color: rgba(0,0,0,0.54);
          }
        }

        &.dock-table-column-fixed {
          flex-basis: auto;
          text-align: center;
          margin: 8px 8px 4px;
        }

        &.dock-table-column-empty {
          display: none;
        }

        &.dock-table-column-check {
          display: none;
        }

        &.dock-table-column-img {
          padding: 0;
          margin-left: auto;
          margin-right: auto;
        }

        &.dock-table-column-icon {
          flex: 0 0 auto;
          order: -2;

          &.dock-table-column-action {
            order: 1;
          }
        }

        &.dock-table-responsive-title,
        &.dock-table-responsive-sub-title {
          text-align: center;
          order: -1;
          padding: 0 16px;

          &[data-label]:before {
            display: none;
          }
        }

        &.dock-table-responsive-title {
          font-size: 1.25em;
        }

        &.dock-table-responsive-sub-title {
          font-size: 1em;
          color: rgba(0,0,0,0.54);
        }

        &.dock-table-responsive-end {
          order: 100;
        }

        &.dock-table-responsive-full-width {
          &[data-label] {
            text-align: center;

            > div {
              display: block;
              margin-left: auto;
              width: auto;
            }

            &:before {
              display: block;
              width: 100%;
              padding-right: 0;
              padding-top: 12px;
              padding-bottom: 6px;
              text-align: center;
            }
          }
        }

        &.dock-table-column-smaller,
        &.dock-table-column-small,
        &.dock-table-column-extra-small {
          flex-basis: 100%;
        }
      }
      &.dock-table-header {
        display: none;
      }
    }
  }
}
