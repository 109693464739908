.dock-login {
  .login-input {
    margin-bottom: 16px;
  }

  .dock-card {
    overflow: hidden;
  }

  .login-logo {
    margin-bottom: 16px;
  }

  .login-gradient {
    position: absolute;
    height: 8px;
    top: 0;
    width: 100%;
    background-image: linear-gradient(to right, #50cded, $brand-secondary);
  }

  .login-form {
    position: relative;
    z-index: 1;
  }

  .login-blur-transition {
    filter: blur(0);
    transition: filter 0.2s;

    &.active {
      filter: blur(5px);
    }
  }

  .login-remember {
    margin-bottom: 24px;
  }

  .login-overlay {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255,255,255,0.5);
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.2s;

    &.active {
      opacity: 0;
      pointer-events: none;
    }
  }
}
