.upload-drop-steps {
  position: relative;
  height: 33%;
  width: 100%;
  transition: all 0.3s;
  top: 0;

  .upload-drop-step {
    height: 100%;
    width: 100%;
    opacity: 0.5;
    transition: all 0.3s;

    &.upload-drop-step-current {
      opacity: 1;
    }

    .btn {
      margin-top: 24px;
    }
  }
}
