@import "../../sass//third-party/material-linear-progress";

.dock-table-row,
.dock-table-inline-row {
    ng-transclude {
        flex: 0 0 100%;
        display: flex;
    }
}

.dock-table-progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
}