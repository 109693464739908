@import "../../sass/components/upload";

.upload-drop-overlay {
  pointer-events: none;
  opacity: 0;
  filter: $upload-blur;
  transition: $upload-transition;
  width: 100%;

  h2 {
    font-size: 3.2em;
    font-weight: 300;
    margin-left: 24px;
    margin-right: 24px;
  }

  .lead {
    margin-bottom: 0.5em;
  }

  .btn {
    margin: 0.5em;
  }

  .upload-drop-overlay-close {
    position: absolute;
    right: 48px;
    top: 48px;
    z-index: 1;
  }

  &.active {
    pointer-events: auto;
    filter: blur(0px);
    opacity: 1;
  }
}
